import { Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import headerBannerImg from "../../assets/images/v_mockup.png";
import colors from "../../constants/colors/colors";

const HeaderBanner = () => {
  return (
    <Box
      w="100%"
      bgGradient="linear-gradient(90deg, #252525, #3a3a3a)" // Slightly darker gradient
      px={{ base: 6, md: 10, lg: 24 }}
      py={{ base: 8, md: 12, lg: 16 }}
      display="flex"
      justifyContent="center"
    >
      <Flex
        maxW="1320px"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ base: "column-reverse", lg: "row" }}
        gap={8} // Spacing between content and image
      >
        {/* Left Content */}
        <VStack
          w="100%"
          spacing={6}
          alignItems="flex-start"
          maxW={{ base: "100%", lg: "50%" }}
        >
          <Heading
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="bold"
            lineHeight="1.2"
            color="#feb101"
            textAlign={{ base: "center", lg: "left" }}
          >
            Empowering Smarter Businesses <br />
            <Text
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              lineHeight="1.2"
              color="#feb101"
              textAlign={{ base: "center", lg: "left" }}
            >
              through Intelligent Insights
            </Text>
          </Heading>

          <Text
            fontSize={{ base: "lg", md: "xl" }}
            color="#ffffff"
            maxW="90%"
            lineHeight="1.8"
            textAlign={{ base: "center", lg: "left" }}
          >
            Streamline operations, boost productivity, and unlock growth
            opportunities with actionable insights. From work reminders to smart
            attendance systems, we simplify your business processes.
          </Text>
          <Box>
            <a
              href="https://play.google.com/store/apps/details?id=com.divishaio.vidhi&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              style={{ display: "inline-block" }}
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                style={{ maxWidth: "240px" }}
              />
            </a>
          </Box>
        </VStack>

        {/* Right Content (Image) */}
        <Image
          src={headerBannerImg}
          alt="header-img"
          objectFit="contain" // Prevents cropping and ensures the image fits inside its container
          h={{ base: "400px", md: "500px", lg: "510px" }} // Increased height for better fit
          w={{ base: "100%", lg: "50%" }} // Adjust width to take only half for larger screens
          fallbackSrc="https://via.placeholder.com/150" // Placeholder in case the image fails to load
        />
      </Flex>
    </Box>
  );
};

export default HeaderBanner;
