import React, { useState, useEffect } from "react";
import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import divisha from "../assets/logo/divisha.png";

function CancellationAndRefundPolicy() {
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);
  return (
    <div className="container">
      <Box mb={4} width={windowWidth > 600 ? "75%" : "90%"}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100vw"
          my={4}
          mr={4}
        >
          <Box>
            <Text className="top-heading" fontSize={{ xxxs: 20, xxs: 34 }}>
              {" "}
              Cancellation/ Refund Policy{" "}
            </Text>
            <Text className="top-sub-heading" fontSize={{ xxxs: 14, xxs: 20 }}>
              {" "}
              Last Updated on October 05, 2022{" "}
            </Text>
          </Box>
          <img src={divisha} alt="logo" height="40px" width="40px" />
        </Flex>

        <Text
          py={3}
          px={2}
          textAlign={windowWidth > 600 ? "center" : "start"}
          className="des"
          fontSize={windowWidth > 600 ? 20 : 16}
        >
          Kindly note, customer can Cancel, Pause, Resume or Change Vidhi
          subscription using Vidhi mobile application or by reaching out to the
          customer support. Subscriptions notifications on Vidhi are
          communicated automatically as per current status.
        </Text>
        <Box className="card" px={8} py={4} my={3}>
          <Text className="top-heading" fontSize={{ xxxs: 20, xxs: 26 }}>
            {" "}
            Cancel a subscription
          </Text>
          <Text className="des" fontSize={windowWidth > 600 ? 20 : 16} mt={2}>
            {" "}
            Uninstalling the app will not cancel your subscription, click cancel
            subscription and confirm by selecting Yes.
          </Text>
        </Box>
        <Box className="card" px={8} py={3} mb={3}>
          <Text className="top-heading" fontSize={{ xxxs: 20, xxs: 26 }}>
            What happens after you cancel
          </Text>
          <Text className="des" fontSize={windowWidth > 600 ? 20 : 16} mt={3}>
            When you cancel a subscription, you’ll still be able to use your
            subscription for the time you’ve already paid. <br />
            For example, if you buy a yearly plan subscription on 10th October
            2022 for INR 2000 and decide to cancel your subscription on 31st
            March 2023: <br />
            <li>
              {" "}
              You’ll have access to the subscription until 09th October 2023.
            </li>
            <li>
              {" "}
              You won’t be charged another yearly subscription of INR 2000 on
              the 10th October 2023.
            </li>
          </Text>
        </Box>

        <Box className="card" px={8} py={3} mb={3}>
          <Text className="top-heading" fontSize={{ xxxs: 20, xxs: 26 }}>
            Pause a subscription
          </Text>
          <Text className="des" fontSize={windowWidth > 600 ? 20 : 16} mt={3}>
            Vidhi will let you pause your subscription. When you pause a
            subscription, your subscription will pause at the end of your
            current billing period. This option can be availed only once.
          </Text>
        </Box>
        <Box className="card" px={8} py={4} mb={3}>
          <Text className="top-heading" fontSize={{ xxxs: 20, xxs: 26 }}>
            Restart a paused subscription
          </Text>
          <Text className="des" fontSize={windowWidth > 600 ? 20 : 16} mt={2}>
            You can resume your subscription anytime.
          </Text>
        </Box>
      </Box>
    </div>
  );
}

export default CancellationAndRefundPolicy;
