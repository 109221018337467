import React from "react";
import About from "../../components/about/about";
import PartnerWithUs from "../../components/about/partnerWithUs";
import ContactUs from "../../components/contact-us/contact-us";
import Header from "../../components/header/header";

function Home1() {
  return (
    <>
      <Header />
      <About />
      <PartnerWithUs />
      <ContactUs />
    </>
  );
}

export default Home1;
