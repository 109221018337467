import { Box, Heading, Text, Button, VStack } from "@chakra-ui/react";

const PartnerWithUs = () => {
  return (
    <Box
      py={10}
      px={{ base: 6, md: 12 }}
      bg="linear-gradient(to bottom, #ffffff 80%, #f2e18d 20%)"
      id="partner"
    >
      <VStack spacing={6} textAlign="center">
        {/* Section Title */}
        <Heading fontSize={{ base: "2xl", md: "3xl" }} color="#feb101">
          Partner With Us
        </Heading>

        {/* Section Text */}
        <Text fontSize={{ base: "md", md: "lg" }} color="gray.700" maxW="800px">
          We are growing company and would be more than happy to partner with
          you. If you are an Investor or a distribution Channel for SMEs, feel
          free to reach out
        </Text>

        {/* Call to Action Button */}
        <Button
          bg="#383838" // Button background color set to dark gray (#383838)
          color="white" // Text color is white for contrast
          size="lg"
          px={8}
          py={6}
          _hover={{
            bg: "#feb101", // Slightly lighter gray on hover
            transition: "all 0.3s ease-in-out", // Smooth transition effect
          }}
          onClick={() => (window.location.href = "/#contact")}
        >
          Become a Partner
        </Button>
      </VStack>
    </Box>
  );
};

export default PartnerWithUs;
