import { Flex, Image, Text } from "@chakra-ui/react";
import logo from "../../assets/images/logo-employee.png";

const Logo = ({
  extraStylesText,
  extraStylesImg,
  width = "auto",
  height = "50",
}) => {
  return (
    <Flex cursor="pointer" alignItems="center" flexDir="column">
      {/* Set width and height directly or use the boxSize prop */}
      <Image
        src={logo}
        alt="logo"
        width={width}
        height={height}
        {...extraStylesImg}
      />
    </Flex>
  );
};

export default Logo;
