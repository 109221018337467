import {
  Accordion,
  AccordionItem,
  Container,
  Heading,
  Text,
  Button,
  Flex,
  Link,
  Box,
} from "@chakra-ui/react";

import vision from "../../assets/images/vision.svg";
import mission from "../../assets/images/mission.svg";

const aboutus = () => {
  return (
    <>
      <Container
        maxW="100%"
        paddingX={0}
        id="about"
        bgGradient="linear-gradient(to bottom, #ffffff, #fef8c3)"
      >
        <Heading
          textAlign="center"
          fontSize="36px"
          pb={10}
          pt={30}
          color="#fff"
          fontWeight="bold"
          letterSpacing="wider"
          bg={"#383838"}
        >
          About Us
        </Heading>

        <Accordion allowToggle>
          <AccordionItem padding="10px" mt={4}>
            <Container maxW="100%" mt={6}>
              <Text
                p={4}
                fontSize="lg"
                fontFamily="Rubik, sans-serif"
                color="#444"
                textAlign="justify"
                lineHeight={1.8}
              >
                Divisha Innovations was founded with a clear mission – to
                empower the growth of small businesses by simplifying and
                optimizing their workforce management. We offer an AI-based
                workforce management system designed to streamline operations
                and drive growth.
              </Text>

              {/* Vision Section */}
              <Container maxW="100%" mt="40px">
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justify="space-between"
                  align="center"
                  textAlign="center"
                  bg="white"
                  borderRadius="10px"
                  boxShadow="md"
                  p={6}
                >
                  <Box flex={{ base: 1, md: 0.5 }} order={{ base: 2, md: 1 }}>
                    <img
                      src={vision}
                      alt="Vision"
                      width={300}
                      height={300}
                      unoptimized
                      style={{
                        borderRadius: "10px",
                        boxShadow: "lg",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Box
                    flex={{ base: 1, md: 0.5 }}
                    order={{ base: 1, md: 0 }}
                    mt={{ base: 4, md: 0 }}
                  >
                    <Text
                      fontSize="3xl"
                      fontWeight="bold"
                      color="#1A73E8"
                      fontFamily="Rubik, sans-serif"
                    >
                      Our Vision
                    </Text>
                    <Text
                      p={2}
                      fontSize="lg"
                      fontFamily="Rubik, sans-serif"
                      color="#555"
                      textAlign="justify"
                    >
                      Our Vision is to design and develop customer-centric
                      products to achieve their goals innovatively and
                      economically. We envision a world where every small
                      business has access to advanced technological tools that
                      can level the playing field and propel them towards
                      success. Through our AI-driven business tools, we strive
                      to create a positive impact on the bottom line of small
                      businesses, allowing them to focus on what they do best –
                      providing exceptional products and services.
                    </Text>
                  </Box>
                </Flex>
              </Container>

              {/* Mission Section */}
              <Container maxW="100%" mt="40px">
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justify="space-between"
                  align="center"
                  textAlign="center"
                  bg="white"
                  borderRadius="10px"
                  boxShadow="md"
                  p={6}
                >
                  <Box flex={{ base: 1, md: 0.5 }} order={{ base: 2, md: 1 }}>
                    <img
                      src={mission}
                      alt="Mission"
                      width={300}
                      height={300}
                      unoptimized
                      style={{
                        borderRadius: "10px",
                        boxShadow: "lg",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Box
                    flex={{ base: 1, md: 0.5 }}
                    order={{ base: 1, md: 0 }}
                    mt={{ base: 4, md: 0 }}
                  >
                    <Text
                      fontSize="3xl"
                      fontWeight="bold"
                      color="#FF5C8D"
                      fontFamily="Rubik, sans-serif"
                    >
                      Our Mission
                    </Text>
                    <Text
                      p={2}
                      fontSize="lg"
                      fontFamily="Rubik, sans-serif"
                      color="#555"
                      textAlign="justify"
                    >
                      Our Mission is that we are committed to our customer
                      success and our approach to delivering this is by
                      empathizing with their needs & goals and embracing their
                      experiences.
                    </Text>
                  </Box>
                </Flex>
              </Container>

              {/* Missing Content */}
              <Container maxW="100%" mt="40px">
                <Text
                  p={4}
                  fontSize="lg"
                  fontFamily="Rubik, sans-serif"
                  color="#444"
                  textAlign="justify"
                  lineHeight={1.8}
                >
                  At Divisha Innovations, we are not just providing a product;
                  we are offering a partnership in your success. Join us on the
                  journey to redefine the way very small businesses manage their
                  workforce, and let's unlock unprecedented growth together.
                  Welcome to a future where technology empowers, simplifies, and
                  propels your business forward.
                </Text>

                <Flex
                  justify="center"
                  align="center"
                  mt={8}
                  fontFamily="Rubik, sans-serif"
                  gap={4}
                >
                  <Text
                    variant="outline"
                    border="none"
                    fontWeight={700}
                    color="#A099CC"
                    size="15"
                    marginBottom={10}
                  >
                    Join us in Our Mission
                  </Text>
                  <Link href="/contact" passHref>
                    <Button
                      as="a"
                      variant="solid"
                      color="#A099CC"
                      bg="white"
                      borderWidth={1}
                      borderColor={"#a099cc"}
                      size="lg"
                      _hover={{
                        background: "#A099CC",
                        color: "white",
                        textDecoration: "none",
                      }}
                      marginBottom={10}
                    >
                      Contact Us
                    </Button>
                  </Link>
                </Flex>
              </Container>
            </Container>
          </AccordionItem>
        </Accordion>
      </Container>
    </>
  );
};

export default aboutus;
