import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import colors from "../../constants/colors/colors";
import HeaderBanner from "./header-banner";

const Header = () => {
  return (
    <Box id="home">
      <HeaderBanner />
    </Box>
  );
};

export default Header;
