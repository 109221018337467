import {
  Box,
  Flex,
  Heading,
  Text,
  Link,
  Divider,
  Image,
} from "@chakra-ui/react";
import logo from "../../assets/images/vidhi_logo.png";

const About = () => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      bgGradient="linear-gradient(to bottom, #ffffff, #fef8c3)"
      py={16}
      px={6}
    >
      {/* Section Container */}
      <Box maxW="1200px" w="100%" textAlign="center">
        {/* Section Heading */}
        <Heading
          fontSize={{ base: "3xl", md: "4xl" }}
          mb={6}
          color="#feb101"
          fontWeight="bold"
        >
          Discover the Power of Vidhi
        </Heading>
        <Text fontSize={{ base: "lg", md: "xl" }} color="gray.700" mb={12}>
          Automate your workflows, enhance productivity, and experience the
          future of business management with Vidhi App and Vidhi for Web.
        </Text>

        {/* Cards and Logo Section */}
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="center"
          align="stretch"
          gap={10}
          w="100%"
        >
          {/* Card 1: Vidhi App */}
          <Box
            bg="white"
            boxShadow="lg"
            borderRadius="lg"
            p={8}
            flex="1"
            minW={{ md: "300px" }}
            textAlign="left"
            display="flex"
            flexDir="column"
            justifyContent="space-between"
          >
            <Heading
              fontSize={{ base: "2xl", md: "3xl" }}
              color="#383838"
              mb={4}
            >
              Vidhi App
            </Heading>
            <Text fontSize="md" color="gray.600" lineHeight="1.8" mb={6}>
              Harness AI and NLP to streamline your operations. Vidhi App
              automates tasks, tracks productivity, and reduces manual errors.
              Simplify business management, from employee oversight to financial
              tracking—all at your fingertips.
            </Text>
            <Link
              href="https://www.vidhiapp.com"
              color="#feb101"
              fontWeight="bold"
              fontSize="lg"
              isExternal
            >
              Explore Vidhi App →
            </Link>
          </Box>

          {/* Logo with Dynamic Divider */}
          <Flex
            direction="column"
            align="center"
            justify="center"
            minH="auto" // Ensure the height matches adjacent cards
          >
            <Divider
              orientation="vertical"
              borderWidth="2px"
              borderColor="#feb101"
              display={{ base: "none", md: "block" }}
              h="100px"
            />
            <Image
              src={logo}
              alt="Vidhi Logo"
              boxSize="80px" // Reduced size for better clarity
              my={4}
              objectFit="contain" // Prevent stretching
              filter="drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))" // Add a subtle shadow
            />
            <Divider
              orientation="vertical"
              borderWidth="2px"
              borderColor="#feb101"
              display={{ base: "none", md: "block" }}
              h="100px"
            />
          </Flex>

          {/* Card 2: Vidhi for Web */}
          <Box
            bg="white"
            boxShadow="lg"
            borderRadius="lg"
            p={8}
            flex="1"
            minW={{ md: "300px" }}
            textAlign="left"
            display="flex"
            flexDir="column"
            justifyContent="space-between"
          >
            <Heading
              fontSize={{ base: "2xl", md: "3xl" }}
              color="#383838"
              mb={4}
            >
              Vidhi for Web
            </Heading>
            <Text fontSize="md" color="gray.600" lineHeight="1.8" mb={6}>
              Experience the future of productivity with our audio-powered web
              platform. Just speak your commands, and Vidhi for Web
              automatically fills forms, manages schedules, and integrates with
              your workflows for a hands-free experience.
            </Text>
            <Link
              href="https://web.vidhiapp.com"
              color="#feb101"
              fontWeight="bold"
              fontSize="lg"
              isExternal
            >
              Discover Vidhi for Web →
            </Link>
          </Box>
        </Flex>

        {/* How It Works Section */}
        <Flex
          mt={16}
          bgGradient="linear-gradient(to bottom, #383838 70%, #383838 90%, #feb101 10%)"
          color="white"
          p={10}
          borderRadius="lg"
          flexDir="column"
          alignItems="center"
          textAlign="center"
        >
          <Heading fontSize={{ base: "2xl", md: "3xl" }} mb={4}>
            How Does Vidhi Work?
          </Heading>
          <Text fontSize="lg" lineHeight="1.8" mb={6}>
            Speak, and Vidhi listens. With AI-powered voice recognition, Vidhi
            processes your commands in real-time. From auto-filling fields to
            managing operations, Vidhi’s hands-free experience ensures
            efficiency and accuracy.
          </Text>
          <Link
            href="https://www.vidhiapp.com/learn-more"
            color="white"
            fontWeight="bold"
            fontSize="lg"
            textDecoration="underline"
            isExternal
          >
            Learn More About Vidhi →
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
};

export default About;
