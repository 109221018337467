import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  Tooltip,
  VStack,
  Grid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import colors from "../../constants/colors/colors";
import Logo from "../logo/logo";
import BsTwitter from "../../assets/logo/twitter.png";
import youtube from "../../assets/logo/youtube.png";
import fb from "../../assets/logo/fb.png";

const Footer = ({ pdf }) => {
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <Box
      px={{ xxxs: 10, xxs: 48 }}
      py={{ xxxs: 10, xxs: 20 }}
      bg="linear-gradient(90deg, #252525, #3a3a3a)" // Gradient background
    >
      {/* Grid Layout for Footer */}
      <Grid
        templateColumns={{
          base: "1fr", // Stack columns vertically on mobile
          sm: "1fr 1fr", // Two columns on small screens
          md: "1fr 1fr 1fr 1fr", // Four columns on medium and larger screens
        }}
        gap={10}
        mb={5}
      >
        {/* Column 1: Address */}
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontWeight="bold" fontSize="xl" color="white">
            Address
          </Text>
          <Text fontSize="md" color="white">
            S3, #104, 13th main, 5th Sector, HSR layout, Bangalore - 560102
            Karnataka, India
          </Text>
          <Text fontSize="md" color="white">
            Mobile: +91-8951029888
          </Text>
        </VStack>

        {/* Column 2: Navigation Links */}
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontWeight="bold" fontSize="xl" color="white">
            Quick Links
          </Text>
          <Link href="/#" color="white" fontSize="md">
            About
          </Link>
          <Link href="/#" color="white" fontSize="md">
            Contact Us
          </Link>
        </VStack>

        {/* Column 3: Policy Links */}
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontWeight="bold" fontSize="xl" color="white">
            Policies
          </Text>
          <Link href="/TermsandConditions" fontSize="md" color="white">
            Terms and Conditions
          </Link>
          <Link href="/privacy_policy.html" fontSize="md" color="white">
            Privacy
          </Link>
          <Link href="/CancellationAndRefundPolicy" fontSize="md" color="white">
            Cancellation and Refund Policy
          </Link>
          <Link href="/account_deletion_request.html" fontSize="md" color="red">
            Data Deletion Request
          </Link>
        </VStack>

        {/* Column 4: Social Media Icons */}
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontWeight="bold" fontSize="xl" color="white">
            Follow Us
          </Text>
          <HStack spacing={6}>
            <Link
              href="https://www.youtube.com/channel/UCobQupYWcQk1UQL90fgqVIA"
              target="_blank"
            >
              <img src={youtube} height={35} width={35} cursor="pointer" />
            </Link>
            <Link href="https://twitter.com/vidhi_business" target="_blank">
              <img src={BsTwitter} height={30} width={30} cursor="pointer" />
            </Link>
            <Link
              href="https://www.instagram.com/Vidhi_Business/"
              target="_blank"
            >
              <Icon
                as={AiOutlineInstagram}
                fontSize={35}
                color="white"
                cursor="pointer"
              />
            </Link>
            <Link
              href="https://www.linkedin.com/company/divisha-innovations/"
              target="_blank"
            >
              <Icon
                as={AiFillLinkedin}
                fontSize={35}
                color="white"
                cursor="pointer"
              />
            </Link>
            <Link
              href="https://www.facebook.com/people/VidhiApp/100089050517791/"
              target="_blank"
            >
              <img src={fb} height={35} width={35} cursor="pointer" />
            </Link>
          </HStack>
        </VStack>
      </Grid>

      {/* Updated Divider with Correct Color */}
      <Divider mb={4} borderBottom="4px solid #feb101" />

      {/* Footer Bottom Section with Increased Font Size */}
      <Text color="white" fontSize="lg" textAlign="center" mt={0}>
        © 2022 Divisha Innovation Pvt. Ltd. All Rights Reserved.
      </Text>
    </Box>
  );
};

export default Footer;
