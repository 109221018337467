import {
  HStack,
  Icon,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Button,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import Logo from "../logo/logo";
const NavBar = () => {
  const [isMobileView] = useMediaQuery("(max-width: 900px)");

  const navItems = [
    { href: "/#", label: "Home" },
    { href: "/aboutus", label: "About Us" },
    { href: "/#contact", label: "Contact" },
  ];

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      py={4}
      px={{ base: 4, md: 10 }}
      w="100%"
      maxW="100vw"
      bg="rgba(255, 255, 255, 0.8)"
      backdropFilter="blur(10px)"
      boxShadow="sm"
      position="sticky"
      top={0}
      zIndex={1000}
      overflow="hidden"
    >
      {/* Logo */}
      <ChakraLink href="/" _hover={{ textDecoration: "none" }}>
        <Logo />
      </ChakraLink>

      {/* Desktop Navigation */}
      {!isMobileView ? (
        <HStack spacing={8} w="100%" justify="flex-end">
          {/* Navigation Links */}
          {navItems.map((item, index) => (
            <ChakraLink
              key={index}
              href={item.href}
              fontSize="16px"
              fontWeight="600"
              position="relative"
              _hover={{
                color: "purple.500",
                textDecoration: "none",
              }}
            >
              {item.label}
            </ChakraLink>
          ))}
        </HStack>
      ) : (
        /* Mobile Navigation */
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HiOutlineMenuAlt1 size={24} />}
            variant="ghost"
            aria-label="Open Menu"
          />
          <MenuList>
            <VStack align="start">
              {/* Navigation Links */}
              {navItems.map((item, index) => (
                <MenuItem key={index} as="a" href={item.href}>
                  {item.label}
                </MenuItem>
              ))}
            </VStack>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};

export default NavBar;
